import React, { useEffect } from 'react';

const IframeComponent = ({ src }: { src: string }) => {
    useEffect(() => {
        // Handler for messages received over the MessageChannel
        const handleMessage = (event: MessageEvent) => {
          // Display an alert with the message content
          alert(event.data);
        };
    
        // Handler for the initial message event that includes the MessageChannel port
        const handleInitialMessage = (event: MessageEvent) => {
          const trustedOrigin = 'https://auth.skiff.earth';
    
          // Check if the message is from a trusted source
          if (event.origin !== trustedOrigin) {
            // Not from a trusted source, ignore the message
            return;
          }
    
          // Check if the message includes a MessagePort
          if (event.ports && event.ports[0]) {
            // Set up the port to listen for messages
            const port = event.ports[0];
            port.onmessage = handleMessage;
    
            // Remove the initial message event listener
            window.removeEventListener('message', handleInitialMessage);
          }
        };
    
        // Add the event listener for the initial 'message' event
        window.addEventListener('message', handleInitialMessage);
    
        // Cleanup function to remove the event listener
        return () => {
          window.removeEventListener('message', handleInitialMessage);
        };
    }, []);

  return (
    <iframe
      src={src}
      style={{ width: '100%', height: '500px', border: 'none' }}
      title="Iframe Example"
    />
  );
};

export default IframeComponent;