import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import SyncFrame from './SyncFrame';


function App() {
  const [authText, setAuthText] = useState('');
  const [authenticatedStatus, setAuthenticatedStatus] = useState(false)

  async function testAuthentication() {
    const response = await fetch('https://pages.skiff.earth/api/isAuthenticated', {
      method: 'GET',
      credentials: 'include',
    });
  
    if (response.status === 200) {
      setAuthText('Authenticated!');
      setAuthenticatedStatus(true);
    } else {
      setAuthText('You are not authenticated!');
      setAuthenticatedStatus(false);
    }
  }

  async function login(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    window.location.href = 'https://auth.skiff.earth/?redirect_uri=https%3A%2F%2Fpages.skiff.earth%2F';
  }

  async function logout(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const response = await fetch('https://pages.skiff.earth/api/logout', {
      method: 'POST',
      credentials: 'include',
    });
  
    if (response.status === 200) {
      setAuthText('You are not authenticated!');
      setAuthenticatedStatus(false);
    } else {
      setAuthText('Unable to logout!');
    }
  }

  useEffect(() => {
    testAuthentication();
  });

  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <h1>Welcome to Pages!</h1>
        <p id='auth-text'>{authText}</p>
        { !authenticatedStatus && <button onClick={login}>Log in</button> }
        { authenticatedStatus && <button onClick={logout}>Log out</button> }
        { authenticatedStatus && <SyncFrame src='https://auth.skiff.earth/syncFrame' /> }
      </header>
    </div>
  );
}

export default App;
